












































.wap-model-container {
  margin: 4px 0;
  border-radius: 0;

  .model-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 0 16px;

    .title {
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .model-content {
    padding: 10px 16px;
    &.active {
      padding: 0;
    }
  }
}
.ba-wap-model-container {
  border-radius: 4px;
  border: 1px solid #5fd7fd;
  box-shadow: 0 1px 2px #bbb;
  margin: 0 8px;
  margin-top: 10px;
  overflow: hidden;

  .model-content {
    padding: 8px 8px 0 8px;
  }

  .model-title {
    background: url("~@/assets/ba_imgs/box-title-bg.png") no-repeat center / 100% 100%;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 34px;
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";