.module-container {
  margin-bottom: 16px;
}
.module-container.active .module-title {
  display: none;
}
.wap-module-container {
  margin-bottom: 8px;
  background: #fff;
}
.wap-module-container.on .module-title.active {
  margin: 0.0375rem;
  background: #f2f4f6;
  border-radius: 0.075rem 0.075rem 0px 0px;
  border-bottom: none;
  padding-left: 15px;
  padding-right: 20px;
}
.wap-module-container.on .module-content.active {
  padding: 0 17px;
}
.wap-module-container .module-title {
  padding: 14px 17px 0;
}
.wap-module-container .module-title.active {
  border-bottom: 1px solid rgba(234, 234, 234, 0.8);
  margin-bottom: 0;
  padding: 14px 0 10px 0;
}
.wap-module-container .module-content {
  padding: 0 17px;
}
.wap-module-container .module-content::-webkit-scrollbar-track {
  background: #fff;
}
.wap-module-container .module-content.active {
  padding: 0;
}
.wapNewBanner {
  margin-bottom: 7px;
}
.wapNewBanner .module-content {
  padding: 0;
}
.module-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
.module-title .t-l {
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.module-title .t-l img {
  display: block;
  width: 17px;
  margin-right: 6px;
}
.module-title .t-r {
  background: #f4f4f4;
  border-radius: 9px;
  font-size: 10px;
  padding: 2px 5px 2px 8px;
  color: #999999;
  display: flex;
  align-items: center;
}
.module-title .t-r img {
  width: 9px;
  height: 9px;
  margin-left: 2px;
}
.module-content {
  overflow-x: auto;
  overflow-y: hidden;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
