.list-box {
  display: flex;
  flex-wrap: wrap;
}
.list-box .item {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: #f9f9f9;
  width: 432px;
  height: 94px;
  padding: 6px;
  margin: 0 8px 12px 0;
}
.list-box .item:nth-child(2n) {
  margin-right: 0;
}
.list-box .item .cover {
  height: 100%;
  width: 107px;
  object-fit: cover;
  border-radius: 4px;
}
.list-box .item .item-r {
  width: calc(100% - 107px);
  padding: 4px 8px 4px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.list-box .item .item-r .title {
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  line-height: 21px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.list-box .item .item-r .bottom-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}
.list-box .item .item-r .bottom-row .user {
  display: flex;
  align-items: center;
}
.list-box .item .item-r .bottom-row .user img {
  width: 16px;
  height: 16px;
  object-fit: cover;
  border: 1px solid #909090;
  margin-right: 8px;
  border-radius: 50%;
}
.wap-list-box .item {
  width: 100%;
  height: 72px;
  margin-right: 0;
  background: none;
  padding: 0;
  margin-bottom: 24px;
}
.wap-list-box .item:nth-child(1) {
  margin-top: 15px;
}
.wap-list-box .item:nth-child(5) {
  margin-bottom: 15px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
