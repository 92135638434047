.wap-model-container {
  margin: 4px 0;
  border-radius: 0;
}
.wap-model-container .model-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 0 16px;
}
.wap-model-container .model-title .title {
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
}
.wap-model-container .model-content {
  padding: 10px 16px;
}
.wap-model-container .model-content.active {
  padding: 0;
}
.ba-wap-model-container {
  border-radius: 4px;
  border: 1px solid #5fd7fd;
  box-shadow: 0 1px 2px #bbb;
  margin: 0 8px;
  margin-top: 10px;
  overflow: hidden;
}
.ba-wap-model-container .model-content {
  padding: 8px 8px 0 8px;
}
.ba-wap-model-container .model-title {
  background: url("~@/assets/ba_imgs/box-title-bg.png") no-repeat center / 100% 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  height: 34px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
