























































































































































.list-box {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background: #f9f9f9;
    width: 432px;
    height: 94px;
    padding: 6px;
    margin: 0 8px 12px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .cover {
      height: 100%;
      width: 107px;
      object-fit: cover;
      border-radius: 4px;
    }

    .item-r {
      width: calc(100% - 107px);
      padding: 4px 8px 4px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #171717;
        line-height: 21px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #999;

        .user {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            object-fit: cover;
            border: 1px solid #909090;
            margin-right: 8px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.wap-list-box {
  .item {
    width: 100%;
    height: 72px;
    margin-right: 0;
    background: none;
    padding: 0;
    margin-bottom: 24px;
    &:nth-child(1) {
      margin-top: 15px;
    }
    &:nth-child(5) {
      margin-bottom: 15px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";